import { JSX }	from "preact";

import { CloseButton }	from "@geotoura/floater/CloseButton";

export type HeadlineProps	= Readonly<{
	text1:	string,
	text2?:	string,
}>;

export const Headline = ({ text1, text2 }:HeadlineProps):JSX.Element =>
	<div class="floater-headline">
		<h2 class="hl hl-3">
			<span class="hl-text1">{text1}</span>
			{ text2 !== undefined && text2 !== "" &&
				<>
					<span> </span>
					<span class="hl-text2">{text2}</span>
				</>
			}
		</h2>
		<CloseButton/>
	</div>;
