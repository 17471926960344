import { Maybe }	from "ts-base/maybe";

import * as primitive		from "@v3/data/primitive";

import { FormSchema }		from "@v3/geko/form/form";
import * as fields			from "@v3/geko/form/fields";
import { ChoiceList }		from "@v3/geko/form/choiceList";

import * as commonModel		from "@geotoura/shared/commonModel";
import * as floaterModel	from "@geotoura/shared/floaterModel";

import { PageInfo }			from "@geotoura/common/pageInfo";

import { Messages }			from "@geotoura/floater/locale";

export const InquirySchema	=
	FormSchema.of({
		email:		fields.RequiredText(primitive.EmailAddress)(null),
		date:		fields.FreeText(""),
		notes:		fields.FreeText(""),
		privacy:	fields.RequiredCheckbox(false),
	});

export const MeetingGeneralSchema	=
	FormSchema.of({
		email:		fields.RequiredText(primitive.EmailAddress)(null),
		notes:		fields.FreeText(""),
		contactWay: fields.FreeRadio<floaterModel.ContactWay>("telephone"),
		privacy:	fields.RequiredCheckbox(false),
	});

export const MeetingPhoneSchema	=
	FormSchema.of({
		phone:		fields.RequiredText(primitive.NonEmptyString)(null),
	});

export const contactWays	= (msg:Messages):ChoiceList<floaterModel.ContactWay> =>
	ChoiceList.of([
		{	key:	"telephone",	model: "telephone",	label:	msg.meeting.contactWay.phone },
		{	key:	"video",		model: "video",		label:	msg.meeting.contactWay.video },
		{	key:	"email",		model: "email",		label:	msg.meeting.contactWay.email },
	]);

export type Model	= Readonly<{
	open:		OpenState|null,
	pageInfo:	PageInfo,
	routeName:	string|null,
	inquiry: {
		data:	InquiryModel,
		state:	FormState,
	},
	meeting: {
		data: {
			general:	MeetingGeneralModel,
			phone:		MeetingPhoneModel,
		},
		state:	FormState,
	},
}>;

export namespace Model {
	export const initial	= (pageInfo:PageInfo):Model => ({
		open:		null,
		routeName:	null,
		pageInfo,
		inquiry: {
			data:	InquirySchema.initial(),
			state:	"form",
		},
		meeting: {
			data: {
				general:	MeetingGeneralSchema.initial(),
				phone:		MeetingPhoneSchema.initial(),
			},
			state: "form",
		},
	});

	export const extractMeetingData	= (model:Model):Maybe<MeetingData>	=> {
		const data = MeetingGeneralSchema.extract(model.meeting.data.general);
		if (model.meeting.data.general.contactWay.value === "telephone") {
			return Maybe.object({
				general:	data,
				phone:		MeetingPhoneSchema.extract(model.meeting.data.phone),
			});
		}
		else {
			return Maybe.object({
				general:	data,
				phone:		Maybe.some({ phone: "" } as const),
			});
		}
	};
}

export type MeetingData	=
	{
		general:	MeetingGeneralData,
		phone:		MeetingPhoneData,
	} |
	{
		general:	MeetingGeneralData,
		phone:		{ phone: "" },
	};

export type Shortcodes = Readonly<{
	routeId:		commonModel.ExampleRouteId|null,
	regionId:		commonModel.RegionId|null,
	territoryId:	commonModel.TerritoryId|null,
	routePdf:		string,
}>;

export type FormState = "form"|"enroute"|"ok"|"error";

export type InquiryModel	= FormSchema.ModelOf<typeof InquirySchema>;
export type InquiryData		= FormSchema.ExtractOf<typeof InquirySchema>;

export type MeetingGeneralModel	= FormSchema.ModelOf<typeof MeetingGeneralSchema>;
export type MeetingGeneralData	= FormSchema.ExtractOf<typeof MeetingGeneralSchema>;

export type MeetingPhoneModel	= FormSchema.ModelOf<typeof MeetingPhoneSchema>;
export type MeetingPhoneData	= FormSchema.ExtractOf<typeof MeetingPhoneSchema>;

export type OpenState = Readonly<{
	content:	"inquiry"|"planner"|"meeting",
	display:	"attached"|"centered",
}>;
