import { JSX }	from "preact";

import * as actions		from "@geotoura/floater/actions";
import { useMessages }	from "@geotoura/floater/useMessages";

export type CloseButtonProps = Readonly<unknown>;

export const CloseButton = ():JSX.Element => {
	const msg = useMessages();

	return (
		<button class="floater-closebutton" onClick={() => actions.close()}>
			<div class="fa-light fa-close"></div>
			<div class="sr-only">{msg.util.close}</div>
		</button>
	);
};
