import * as sharedLocale	from "@geotoura/shared/sharedLocale";
import * as i18n			from "@geotoura/shared/i18n";

import { messages as de } from "@geotoura/floater/locale_de";
import { messages as en } from "@geotoura/floater/locale_en";
import { messages as it } from "@geotoura/floater/locale_it";

export type Messages = Readonly<{
	app: {
		title:		string,
		inquiry1:	string,
		inquiry2:	string,
		planner1:	string,
		planner2:	string,
		meeting1:	string,
		meeting2:	string,
	},
	form: {
		fieldRequired:		string,
		fieldInvalid:		string,
		privacyAgreement:	sharedLocale.EmbeddedLink,

	},
	inquiry: {
		introGeneral:	string,
		introRoute:		string,
		email:			sharedLocale.TextField,
		date:			sharedLocale.TextField,
		notes:			sharedLocale.TextField,
		submitLabel:	string,
	},
	meeting: {
		intro:		string,
		email:		sharedLocale.TextField,
		phone:		sharedLocale.TextField,
		notes:		sharedLocale.TextField,
		contactWay: {
			label:	string,
			email:	string,
			phone:	string,
			video:	string,
		},
		submitLabel:	string,
	},
	planner: {
		text1:			string,
		text2:			string,
		text3:			string,
		textmobile1:	string,
		textmobile2:	string,
		linkLabel:		string,
		wordpressBase:	string,
	},
	util: {
		success:{
			title:	string,
			text:	string,
		},
		error: {
			title:	string,
			text1:	string,
			mail:	sharedLocale.Link,
			text2:	string,
			tel:	sharedLocale.Link,
		},
		signature:	string,
		close:		string,
	},
}>;

export namespace Messages {
	export const of:i18n.Localized<Messages>	= { de, en, it };
}
