/* eslint-disable @stylistic/quote-props */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck

// import * as variants from "@geotoura/common/variants";

export type TrackingReport = Readonly<{
	action:		string,
	category:	string,
	label:		string,
	value:		number,
}>;

export const gaSendEvent = (_report:TrackingReport):void => {
	/*
	// commented out for two reasons:
	// 1) since load of google tag manager from start we have _always_ a gtag function and
	// can therefore not rely on its non-existence to send no data of people who opted out of tracking
	// before activating it again we need to check the consent status
	//
	// 2) since the switch from Google Universal Analytics to Google Analytics 4 in Feb/March 2024
	// the structure category-label-value is not displayed in a meaning ful way on the analytics Website anymore

	if (typeof gtag === "function") {
		// logger.info("report to Google Analytics", report);
		gtag("event", report.action, {
			"event_category":	report.category,
			"event_label":		report.label + " variants:" + variants.trackingString(),
			"value":			report.value,
		});
	}
	else {
		logger.info("no ga function", report);
	}
	*/
};

export const loadTrackers = ():void => {
	if (typeof gtag !== "function") {
		logger.error("no gtag function");
		return;
	}
	gtag("consent", "update", {
		"ad_storage":			"granted",
		"ad_user_data":			"granted",
		"ad_personalization":	"granted",
		"analytics_storage":	"granted",
	});

	// per request by Ole Albrecht from adseed to trigger Bing updating its consent settings
	// per 2024-06 this is also used to trigger facebook tracking integration
	gtag("event", "ms_ads_consent");
};

export const unloadTrackers = ():void => {
	if (typeof gtag !== "function") {
		logger.error("no gtag function");
		return;
	}
	gtag("consent", "update", {
		"ad_storage":			"denied",
		"ad_user_data":			"denied",
		"ad_personalization":	"denied",
		"analytics_storage":	"denied",
	});
};
