import { Branded }			from "ts-base/branded";

import { Enum }				from "@v3/data/enum";
import { Numeric }			from "@v3/data/numeric";
import { NaturalNumber }	from "@v3/data/primitive";

import { CountryId }		from "@geotoura/shared/i18n";

export type DestinationId	= Numeric.Value<typeof DestinationId>;
export const DestinationId	= NaturalNumber.rebrand("DestinationId");

export type HotelId			= Numeric.Value<typeof HotelId>;
export const HotelId		= NaturalNumber.rebrand("HotelId");

export type RegionId		= Numeric.Value<typeof RegionId>;
export const RegionId		= NaturalNumber.rebrand("RegionId");

export type TerritoryId		= Numeric.Value<typeof TerritoryId>;
export const TerritoryId	= NaturalNumber.rebrand("TerritoryId");

export type ExampleRouteId	= Numeric.Value<typeof ExampleRouteId>;
export const ExampleRouteId	= NaturalNumber.rebrand("ExampleRouteId");

export type CarCategoryId	= Numeric.Value<typeof CarCategoryId>;
export const CarCategoryId	= NaturalNumber.rebrand("CarCategoryId");

export type CarCategory = Readonly<{
	id:		CarCategoryId,
	name:	string,
}>;

export type HotelCategoryId		= Numeric.Value<typeof HotelCategoryId>;
export const HotelCategoryId	= NaturalNumber.rebrand("HotelCategoryId");

export type HotelCategory = Readonly<{
	id:		HotelCategoryId,
	name:	string,
}>;

export type HotelSiteId		= Numeric.Value<typeof HotelSiteId>;
export const HotelSiteId	= NaturalNumber.rebrand("HotelSiteId");

export type HotelSite = Readonly<{
	id:		HotelSiteId,
	name:	string,
}>;

export type IsoCountry	= Readonly<{
	code:	CountryId,
	name:	string,
}>;

export type RouteName = Readonly<{
	name:	string,
}>;

export const MapAssetFileType	= Enum.of("driving", "static");
export type MapAssetFileType	= Enum.Value<typeof MapAssetFileType>;

//-----------------------------------------------------------------------------

// we send lots of these in the up api, therefore the property names have to be short
export type Coordinate	= Readonly<{
	lat:	Latitude,
	lng:	Longitude,
}>;

export type Latitude	= Branded<number, "Latitude">;
export const Latitude	= Branded.brand<Latitude>;

export type Longitude	= Branded<number, "Longitude">;
export const Longitude	= Branded.brand<Longitude>;

export type SecondDuration	= Branded<number, "SecondDuration">;
export const SecondDuration	= Branded.brand<SecondDuration>;

export type MeterDistance	= Branded<number, "MeterDistance">;
export const MeterDistance	= Branded.brand<MeterDistance>;

//-----------------------------------------------------------------------------

export type HtmlSnippet		= Branded<string, "HtmlSnippet">;
export const HtmlSnippet	= Branded.brand<HtmlSnippet>;
