import { JSX }	from "preact";

import { useMessages }	from "@geotoura/floater/useMessages";
import * as actions		from "@geotoura/floater/actions";
import { Headline }		from "@geotoura/floater/Headline";

export type ErrorProps = Readonly<unknown>;

export const Error = ():JSX.Element => {
	const msg = useMessages();

	return (
		<div class="floater-error">
			<Headline text1={msg.util.error.title}/>
			<p>{msg.util.error.text1} <a class="textlink" href={msg.util.error.mail.href}>{msg.util.error.mail.text}</a> {msg.util.error.text2} <a class="textlink" href={msg.util.error.tel.href}>{msg.util.error.tel.text}</a></p>
			<div class="signature">{msg.util.signature}</div>

			<div class="button-bar button-bar--single">
				<button class="primary-button" onClick={() => actions.close()}>
					{msg.util.close}
				</button>
			</div>
		</div>
	);
};
