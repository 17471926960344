import { JSX }	from "preact";

import { specialPages }	from "@geotoura/shared/constants";

import { PageInfo }		from "@geotoura/common/pageInfo";

import { useMessages }	from "@geotoura/floater/useMessages";
import { Headline }		from "@geotoura/floater/Headline";

export type PlannerProps	= Readonly<{
	pageInfo:	PageInfo,
}>;

export const Planner = ({ pageInfo }:PlannerProps):JSX.Element => {
	const msg = useMessages();

	const params =
			pageInfo.type === "territory"	? `?territory=${pageInfo.territoryId}`
		:	pageInfo.type === "region"		? `?region=${pageInfo.regionId}`
		:	pageInfo.type === "route"		? `?exampleRouteId=${pageInfo.routeId}` : "";
	const url = `/${msg.planner.wordpressBase}/${specialPages.planner}/${params}`;

	return (
	<div class="floater-dialog planner">
		<Headline text1={msg.app.planner1} text2={msg.app.planner2}/>
		<div class="floater-columns">
			<div class="floater-col-1">
				<img class="floater-image" src="/img/floater/planner-example.png"/>
			</div>
			<div class="floater-col-2">
				<div class="floater-p-wrapper">
					<p>{msg.planner.text1}</p>
					<p>{msg.planner.text2}</p>
					<p class="floater-p-emphasis">{msg.planner.text3}</p>
				</div>
				<a href={url} class="primary-button" target="_blank">{msg.planner.linkLabel}</a>
			</div>
		</div>
		<div class="floater-columns-mobile">
			<img class="floater-image-mobile" src="/img/floater/planner-example-mobile.png"/>
			<div class="floater-p-wrapper-mobile">
				<p>{msg.planner.textmobile1}</p>
				<p>{msg.planner.textmobile2}</p>
			</div>
			<div class="floater-button-bar">
				<a href={url} class="primary-button" target="_blank">{msg.planner.linkLabel}</a>
			</div>
		</div>
	</div>
	);
};
