import { JSX }	from "preact";

import { classes }	from "ts-base/web/dom";

import * as i18n				from "@geotoura/shared/i18n";

import { ProvideLanguageCode }	from "@geotoura/common/util/useLanguageCode";

import { ProvideMessages }	from "@geotoura/floater/useMessages";
import { Messages }			from "@geotoura/floater/locale";
import { Model }			from "@geotoura/floater/model";
import * as actions			from "@geotoura/floater/actions";

import { Inquiry }	from "@geotoura/floater/Inquiry";
import { Planner }	from "@geotoura/floater/Planner";
import { Meeting }	from "@geotoura/floater/Meeting";
import { Button }	from "@geotoura/floater/Button";

export type AppProps = Readonly<{
	languageCode:	i18n.LanguageCode,
	msg:			Messages,
	model:			Model,
}>;

export const App = ({ languageCode, msg, model }:AppProps):JSX.Element =>
	<ProvideLanguageCode value={languageCode}>
	<ProvideMessages value={msg}>
		<div class={classes(
			"floater-inner",
			model.open !== null && "expanded",
			model.open !== null && model.open.display
		)}>
			<div class="floater-backdrop">
				<div class="floater-dialog-wrapper">
					{ model.open !== null && model.open.content === "inquiry" &&
						<Inquiry
							data={model.inquiry.data}
							state={model.inquiry.state}
							routeName={model.routeName}
							pageInfo={model.pageInfo}
							modifyInquiryForm={actions.modifyInquiryForm}
						/>
					}
					{ model.open !== null && model.open.content === "planner" &&
						<Planner pageInfo={model.pageInfo}/>
					}
					{ model.open !== null && model.open.content === "meeting" &&
						<Meeting
							data={model.meeting.data}
							state={model.meeting.state}
							modifyGeneral={actions.modifyMeetingGeneralForm}
							modifyPhone={actions.modifyMeetingPhoneForm}
						/>
					}
				</div>
			</div>
			<div class="floater-buttonbar">
				<div class="floater-start">
					<div class="floater-start-text">{msg.app.title}</div>
				</div>
				<Button
					icon="envelope"
					text1={msg.app.inquiry1}
					text2={msg.app.inquiry2}
					open={model.open !== null && model.open.content === "inquiry"}
					action={() => actions.toggle("inquiry", "attached")}
				/>
				<Button
					icon="magnifying-glass-location"
					text1={msg.app.planner1}
					text2={msg.app.planner2}
					open={model.open !== null && model.open.content === "planner"}
					action={() => actions.toggle("planner", "attached")}
				/>
				<Button
					icon="comments-question-check"
					text1={msg.app.meeting1}
					text2={msg.app.meeting2}
					open={model.open !== null && model.open.content === "meeting"}
					action={() => actions.toggle("meeting", "attached")}
				/>
			</div>
			<div class="floater-starter-buttons">
				<button class="js-inquiry" onClick={() => actions.toggle("inquiry", "centered")}></button>
				<button class="js-meeting" onClick={() => actions.toggle("meeting", "centered")}></button>
			</div>
		</div>
	</ProvideMessages>
	</ProvideLanguageCode>;
