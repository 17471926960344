// these page names are defined by the special pages in the Geotoura theme
// page-offer.php, page-planner.php and page-traveldata.php (page-{slug}.php)
// see https://developer.wordpress.org/themes/basics/template-hierarchy/
// on each instance must exist one page with each slug

type SpecialPages = Readonly<{
	offer:		string,
	planner:	string,
	traveldata:	string,
}>;

export const specialPages:SpecialPages = {
	offer:		"offer",
	planner:	"planner",
	traveldata: "traveldata",
};
