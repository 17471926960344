import { JSX }	from "preact";

import { classes }	from "ts-base/web/dom";

import { useMessages }	from "@geotoura/floater/useMessages";
import * as actions		from "@geotoura/floater/actions";
import { Headline }		from "@geotoura/floater/Headline";

export type SuccessProps	= Readonly<{
	/* DO NOT CHANGE: these strings are used for conversion tracking by Google Tag Manager */
	conversionType:	"conversion-meeting"|"conversion-inquiry",
}>;

export const Success = ({ conversionType }:SuccessProps):JSX.Element => {
	const msg = useMessages();

	return (
		<div class={classes(
			"floater-success",
			conversionType
		)}>
			<Headline text1={msg.util.success.title}/>
			<p>{msg.util.success.text}</p>
			<div class="signature">{msg.util.signature}</div>
			<div class="button-bar button-bar--single">
				<button class="primary-button" onClick={() => actions.close()}>
					{msg.util.close}
				</button>
			</div>
		</div>
	);
};
