import { JSX }	from "preact";

import { classes }	from "ts-base/web/dom";

export type ButtonProps = Readonly<{
	icon:	string,
	text1:	string,
	text2:	string,
	open:	boolean,
	action:	() => void,
}>;

export const Button = ({ icon, text1, text2, open, action }:ButtonProps):JSX.Element =>
	<button
		class={classes("floater-button", open && "expanded")}
		onClick={action}
	>
		<div class={`fa-light fa-${icon}`}></div>
		<div class="floater-button-text">
			<div class="floater-button-text-1">{text1}</div>
			<div class="floater-button-text-2">{text2}</div>
		</div>
	</button>;
