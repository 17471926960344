/* eslint-disable @stylistic/comma-dangle */

import { sharedLocale }	from "@geotoura/shared/sharedLocale_en";
import { siteInfo }		from "@geotoura/shared/siteInfo_en";

import { Messages } from "@geotoura/floater/locale";

export const messages:Messages = {
	app: {
		title:		"Quick start",
		inquiry1:	"Request",
		inquiry2:	"offer",
		planner1:	"Plan",
		planner2:	"individually",
		meeting1:	"Consult",
		meeting2:	"personally"
	},
	form: {
		fieldRequired:		"This field is mandatory.",
		fieldInvalid:		"The value of this field is invalid.",
		privacyAgreement:	sharedLocale.privacyAgreement
	},
	inquiry: {
		introGeneral:	"Please send me a free and non-binding travel offer.",
		introRoute:		"Please send me a free and non-binding offer for the route",
		email: {
			label:			"E-mail",
			placeholder:	"Your e-mail address",
		},
		date: {
			label:			"Travel date",
			placeholder:	"When do you want to travel?",
		},
		notes: {
			label:			"My wishes",
			placeholder:	"There is space here for your wishes: places you would like to visit, special requirements, etc.",
		},
		submitLabel:	"Send offer"
	},
	meeting: {
		intro:	"Arrange a personal travel consultation (Monday to Friday from 9.30 a.m. to 6 p.m.)",
		email: {
			label:			"E-Mail",
			placeholder:	"Your e-mail address",
		},
		phone: {
			label:			"Telephone",
			placeholder:	"Your telephone number",
		},
		notes: {
			label:			"Date and wishes",
			placeholder:	"Please let us know your preferred date and the topic (destination, type of trip …) you are interested in",
		},
		contactWay: {
			label:	"How would you prefer to speak to us?",
			email:	"E-mail",
			phone:	"Telephone",
			video:	"Video conference"
		},
		submitLabel:	"Arrange a consultation"
	},
	planner: {
		text1:			"Use our holiday planner! Design your dream holiday according to your own personal ideas.",
		text2:			"On an interactive map, you can easily put together travel destinations, overnight stays and extras.",
		text3:			"A holiday couldn't be more individual!",
		textmobile1:	"Design your dream holiday according to your own personal ideas.",
		textmobile2:	"On an interactive map, you can easily put together travel destinations, overnight stays and extras.",
		linkLabel:		"Start your holiday planner now",
		wordpressBase:	siteInfo.wordpressBase,
	},
	util: {
		success:{
			title:	"Your message has been sent",
			text:	"Thank you for your enquiry. We will reply as soon as possible."
		},
		error: {
			title:	"An error has occurred",
			text1:	"Sorry, your message could not be sent. Please try again later, contact us by e-mail at",
			mail:	sharedLocale.email.info,
			text2:	"or simply give us a call at",
			tel:	sharedLocale.phone,
		},
		signature:	"Your Geotoura team",
		close:		"Close",
	}
};
